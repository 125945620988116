import React, { useState, useRef, useEffect } from 'react'

import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Modal, Avatar, Space, Input, InputNumber, Button, Popconfirm, Table, Select, Spin } from 'antd'

import Layout from '../components/layout/Layout'
import axiosInstance from '../utils/axiosInstance'

function EditFocusArea() {
  const navigate = useNavigate()
  let { parentId, childId } = useParams()
  const [subFocus, setSubFocus] = useState({})
  const [loading, setLoading] = useState(false)
  const [form, setForm] = useState({})

  const onChange = (obj) => {
    // console.log(obj)
    setForm((prev) => ({ ...prev, ...obj }))
  }

  const getFocusAreas = async (v) => {
    try {
      setLoading(true)

      const response = await axiosInstance.get(`/api/v1/focus/getFocusAreaById?focusId=${parentId}`)

      setSubFocus(response.data.focusArea.subCategories.filter((el) => el.id == childId)[0])

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  const editSubFocus = async () => {
    try {
      // console.log(form)
      // return

      setLoading(true)

      const response = await axiosInstance.put(`/api/v1/focus/editSubFocus?focusId=${parentId}&subFocusId=${childId}`, form)

      // console.log(response.data)

      navigate(`/focusArea/${parentId}`)

      setLoading(false)
    } catch (e) {
      console.log(e)
      setLoading(false)
    }
  }

  useEffect(() => {
    getFocusAreas()
  }, [])

  return (
    <Layout>
      <div>
        <Button
          onClick={() => {
            navigate(`/focusArea/${parentId}`)
          }}
        >
          Back
        </Button>
        <h2>Edit Sub focus</h2>
        {/* {JSON.stringify(subFocus)} */}
        {loading && <Spin />}
        {Object.keys(subFocus).length > 0 && (
          <div>
            <span>Sub title</span>
            <Input placeholder="Sub title" defaultValue={subFocus.subTitle} onChange={(e) => onChange({ subTitle: e.target.value })} style={{ marginTop: 10 }} />
            <div style={{ marginTop: 15 }} />
            <span>Points</span>
            <br />
            <InputNumber placeholder="Points" min={1} max={10000} defaultValue={subFocus.points} onChange={(e) => onChange({ points: e })} style={{ marginTop: 10 }} />
            <div style={{ marginTop: 15 }} />
            <span>Quick Description</span>
            <Input
              placeholder="Quick Description"
              defaultValue={subFocus.quickDescription}
              onChange={(e) => onChange({ quickDescription: e.target.value })}
              style={{ marginTop: 10 }}
            />
          </div>
        )}

        <Button
          style={{ marginTop: 10 }}
          type="primary"
          onClick={() => {
            //
            editSubFocus()
          }}
        >
          Update
        </Button>
      </div>
    </Layout>
  )
}

export default EditFocusArea
